import React from 'react'

interface TabsState {
  preferredTabs: LooseObject;
  setPreferredTab: (tab: string, group?: string) => void
}

interface LooseObject {
  [key: string]: any
}

const defaultState: TabsState = {
  preferredTabs: {} as LooseObject,
  // tslint:disable-next-line: no-empty
  setPreferredTab: (tab: string, group?: string) => {},
}

const TabsContext = React.createContext(defaultState)

class TabsProvider extends React.Component {
  public state = {
    preferredTabs: {},
  }

  public setPreferredTab = (preferredTab: string, group?: string) => {
    if(group) {
      const newState = { ...this.state } as LooseObject
      newState.preferredTabs[group] = preferredTab
      this.setState(newState)

      localStorage.setItem('preferredTabs', JSON.stringify(newState.preferredTabs)
      )
    }
  }

  public componentDidMount() {
    // Getting tab value from localStorage!
    const preferredTabs = JSON.parse(`${localStorage.getItem('preferredTabs')}`)
    if (preferredTabs) {
      this.setState({
        ...this.state,
        preferredTabs
      })
    }
  }

  public render() {
    const { children } = this.props
    const { preferredTabs } = this.state
    return (
      <TabsContext.Provider
        value={{
          preferredTabs,
          setPreferredTab: this.setPreferredTab,
        }}
      >
        {children}
      </TabsContext.Provider>
    )
  }
}
export default TabsContext
export { TabsProvider }
